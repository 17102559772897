<!--
 * @Date: 2023-03-24 08:43:00
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-04-21 09:15:17
 * @Description: 
-->
<template>
  <div class="my-swiper-pagination" :style="{ width: maxLen * width + 'px' }">
    <div
      class="my-swiper-pagination-processs"
      :class="{ 'not-timer': timer === null }"
      :style="{ width: processWidth + 'px' }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    width: Number,
    maxLen: Number,
    index: Number,
    delay: Number
  },
  data() {
    return {
      timer: null,
      processWidth: ''
    };
  },
  computed: {},
  watch: {
    index: {
      handler(news) {
        this.clearTimer();
        this.$nextTick(() => {
          this.processWidth = news * this.width;
          this.setTimer();
        });
      },
      deep: true
    }
  },
  mounted() {
    this.processWidth = this.index * this.width;
    this.setTimer();
  },
  methods: {
    setTimer() {
      if (this.timer) {
        this.clearTimer();
      }
      this.timer = setInterval(() => {
        this.processWidth += (this.width / this.delay) * 50;
        if (
          this.processWidth >=
          (this.index + 1 > this.maxLen ? this.maxLen : this.index + 1) * this.width
        )
          this.clearTimer();
      }, 50);
    },
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
};
</script>

<style lang="less" scoped>
.my-swiper-pagination {
  background: unset;
  position: relative;
  .my-swiper-pagination-processs {
    float: left;
    height: 4px;
    background: rgba(0, 106, 255, 1);
    &.not-timer {
      transition: all linear 0.3s;
    }
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background: #fff;
    z-index: -1;
  }
}
</style>
