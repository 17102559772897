/*
 * @Date: 2023-03-30 18:10:06
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-05-24 15:22:03
 * @Description:
 */
import Vue from 'vue';
import '@/assets/css/init.less';
import '@/core/element';
import { service } from '@/api/request';

Vue.prototype.$service = service;

// check width
const checkWidth = function () {
  if (window.innerWidth < 1240) {
    let matchs = [new RegExp(`^\/$`), new RegExp('')];
    let url = window.location.href.replace(window.location.origin, '');
    // 判断是否可以适配
    if (/^\/[$|business.htm(.+)|plan.html(.+)|about.html(.+)]/.test(url)) {
      window.location.href = process.env.VUE_APP_H5 + url.replace('.html', '');
    } else {
      window.location.href = process.env.VUE_APP_H5;
    }
  }
};

// H5 适配
window.addEventListener('resize', checkWidth);

checkWidth();
