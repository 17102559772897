/*
 * @Date: 2023-03-16 15:39:37
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-04-02 19:46:01
 * @Description:
 */
import Vue from 'vue';
import { Menu, Submenu, MenuItem, Input } from 'element-ui';

Vue.component(Menu.name, Menu);
Vue.component(Submenu.name, Submenu);
Vue.component(MenuItem.name, MenuItem);
Vue.component(Input.name, Input);
