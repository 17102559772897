<!--
 * @Date: 2023-03-22 10:08:20
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-05-05 10:55:21
 * @Description: 
-->
<template>
  <div class="sub-menu">
    <div class="sub-menu-title">{{ title }}</div>
    <div class="sub-menu-box">
      <div class="left">
        <div
          class="sub-menu-cell"
          v-for="(item, index) in menu"
          @click="to(item.url)"
          :class="{ active: checkActive(item.url) }"
        >
          {{ item.title }}
          <img src="@/assets/imgs/home_drop-down menu_icon_arrow.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: String,
    menu: Array
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {
    to(url) {
      window.location.href = url;
    },
    checkActive(url) {
      return (this.$route ? this.$route.fullPath : window.location.href).indexOf(url) >= 0;
    }
  }
};
</script>

<style lang="less" scoped>
.sub-menu {
  width: 1240px;
  padding: 0 20px 20px;
  .sub-menu-title {
    margin: 0 20px;
    line-height: 60px;
    border-bottom: 1px solid rgba(209, 228, 255, 0.4);
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #4e5b6d;
    margin-bottom: 21px;
  }
  .sub-menu-box {
    line-height: 80px;
    padding: 0 20px;
    font-family: PingFangSC-SNaNpxibold;
    font-weight: 600;
    font-size: 16px;
    color: #4e5b6d;
    line-height: 24px;
    display: flex;
    .left {
      flex: 1;
      min-width: 0;
      overflow: hidden;
      .sub-menu-cell {
        float: left;
        width: 25%;
        position: relative;
        img {
          opacity: 0;
        }
        &:hover,
        &.active {
          color: rgb(27, 121, 255);
          box-shadow: 0px 5px 13px 1px rgba(27, 116, 255, 0.1);
          img {
            opacity: 1;
          }
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #f5f7f9;
            z-index: -1;
          }
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.62;
            background: #f5f7f9;
            filter: blur(2px);
            z-index: -1;
          }
        }
      }
    }
    .sub-menu-cell {
      height: 80px;
      display: flex;
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;
      font-family: PingFangSC-SNaNpxibold;
      font-weight: 600;
      font-size: 16px;
      color: #4e5b6d;
      cursor: pointer;
      &:hover {
        color: #1b79ff;
      }
    }
  }
}
</style>
