<!--
 * @Date: 2023-03-22 17:42:13
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-04-07 09:38:48
 * @Description: 
-->
<template>
  <div class="box">
    <slot name="top"></slot>
    <div class="box-title" v-if="title">
      {{ title }}
      <img class="box-title-icon" src="@/assets/imgs/business area_title_sign.png" alt="" />
    </div>
    <div class="box-info" v-if="info" v-html="info"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    info: String
  }
};
</script>

<style lang="less" scoped>
.box {
  width: 1241px;
  margin: 0 auto;
  .box-title {
    font-family: PingFangSC-Medium;
    font-weight: 500;
    font-size: 40px;
    color: #04152f;
    line-height: 60px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 26px;
    .box-title-icon {
      width: 54px;
      height: auto;
      margin-bottom: 16px;
      margin-left: 12px;
    }
  }
  .box-info {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 16px;
    color: #4e5b6d;
    line-height: 28px;
  }
}
</style>
