/*
 * @Date: 2023-03-23 09:38:44
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-04-23 09:09:46
 * @Description:
 */
export const ZXFW = [
  {
    title: '建筑企业数字化转型咨询',
    img: 'http://ow-web.hysz.co/business/zxfw/建筑企业信息化转型咨询.png'
  },
  {
    title: '智慧城市数字化咨询',
    img: 'http://ow-web.hysz.co/business/zxfw/智慧城市信息化咨询.png'
  },
  { title: 'BIM全过程咨询', img: 'http://ow-web.hysz.co/business/zxfw/BIM全过程咨询.png' },
  {
    title: '可视化视觉应用咨询',
    img: 'http://ow-web.hysz.co/business/zxfw/可视化视觉应用咨询.png'
  },
  {
    title: '城市空间实景数据咨询',
    img: 'http://ow-web.hysz.co/business/zxfw/城市空间实景数据咨询.png'
  },
  {
    title: '弱电工程全过程咨询',
    img: 'http://ow-web.hysz.co/business/zxfw/弱电工程全过程咨询.png'
  }
];

export const DZKF = [
  { title: 'web软件开发', img: 'http://ow-web.hysz.co/business/dzkf/WEB软件开发.png' },
  { title: '移动app开发', img: 'http://ow-web.hysz.co/business/dzkf/移动APP开发.png' },
  { title: '小程序开发', img: 'http://ow-web.hysz.co/business/dzkf/小程序开发.png' },
  { title: '公众号开发', img: 'http://ow-web.hysz.co/business/dzkf/公众号开发.png' }
];

export const CPFW = [
  { title: '数智工地', img: 'http://ow-web.hysz.co/business/cpfw/数字工地.png' },
  { title: '智慧园区', img: 'http://ow-web.hysz.co/business/cpfw/智慧园区.png' },
  { title: '城市运维', img: 'http://ow-web.hysz.co/business/cpfw/城市运维.png' }
];
export const JCFW = [
  { title: '软件集成', img: 'http://ow-web.hysz.co/business/jcfw/软件集成.png' },
  { title: '硬件集成', img: 'http://ow-web.hysz.co/business/jcfw/硬件集成.png' },
  { title: '技术集成', img: 'http://ow-web.hysz.co/business/jcfw/技术集成.png' },
  { title: '数据集成', img: 'http://ow-web.hysz.co/business/jcfw/数据集成.png' },
  { title: '设施集成', img: 'http://ow-web.hysz.co/business/jcfw/设施集成.png' },
  { title: '组织集成', img: 'http://ow-web.hysz.co/business/jcfw/组织集成.png' }
];
export const YJKF = [
  { title: '智能安全帽', img: 'http://ow-web.hysz.co/business/yjkf/img01.png' },
  {
    title: '塔机安全监测与驾驶辅助显示设备',
    img: 'http://ow-web.hysz.co/business/yjkf/img02.png'
  },
  { title: '智能洗车槽分析设备', img: 'http://ow-web.hysz.co/business/yjkf/img03.png' },
  { title: '工地安全AI识别设备', img: 'http://ow-web.hysz.co/business/yjkf/img04.png?v=123' }
];
