<template>
  <div id="footer">
    <div class="contact">
      <img class="bg" src="@/assets//imgs/footer/bg.webp" alt="" />
      <div class="content">
        <div class="title">
          汇科智创致力于以“超高效”的方式，让每一个产品都成为全球行业前列的贡献者
        </div>
        <div class="button" @click="ask">
          <a href="javascript:;">立即咨询</a>
        </div>
      </div>
    </div>
    <div class="base">
      <div class="info">
        <div class="text-box">
          <div>Aboust Us</div>
          <div>
            <a href="/about.html">关于我们</a>
          </div>
          <div>
            <a href="/privacy.html">隐私政策</a>
          </div>
          <div>
            <a href="/law.html">法律信息</a>
          </div>
        </div>
        <div class="text-box">
          <div>Contact Us</div>
          <div>电话 027-8495 0566</div>
          <div>邮箱 hkzc@focus-in.cn</div>
          <div>
            地址 湖北省武汉市汉阳区四新北路682号
            <br />
            汉阳市政建设大厦
          </div>
        </div>
        <div class="text-box">
          <div>Join Us</div>
          <div>
            <a href="/join.html">加入我们</a>
          </div>
          <div>
            <a
              href="https://jobs.51job.com/all/coB2YAZQVhAzoGZAFqVTM.html?timestamp__1258=QqAx9DRDcDyD0D0xxBu%3Dx277kci%2BUiCeD&alichlgref=https%3A%2F%2Fwe.51job.com%2F"
              target="_blank"
            >
              其它
            </a>
          </div>
        </div>
        <div class="icon">
          <img src="@/assets//imgs/logo2.webp" alt="" />
        </div>
      </div>
      <div class="icp">
        <span>Copyright©2021 FOCUZ-IN.com All rights reserved.</span>
        <span>
          <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2022011376号-3</a>
        </span>
        <span>鄂公网安备 42010502200192号</span>
      </div>
    </div>
  </div>
</template>

<script>
import hook from './hook';
export default {
  methods: {
    ask() {
      hook.$emit('open');
    }
  }
};
</script>

<style lang="less" scoped>
#footer {
  width: 100vw;
  .contact {
    height: 200px;
    position: relative;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
      user-select: none;
      z-index: -1;
    }
    .content {
      width: 1236px;
      height: 100%;
      margin: 0 auto;
      padding-top: 49px;
      .title {
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 24px;
        color: #04152f;
        line-height: 36px;
        margin-bottom: 18px;
      }
      .button {
        cursor: pointer;
        user-select: none;
        width: 144px;
        line-height: 48px;
        background: #006aff;
        text-align: center;
        font-family: PingFangSC-SNaNpxibold;
        font-weight: 600;
        font-size: 16px;
      }

      a {
        color: #a4a5a8;
        text-decoration: unset;
        color: #ffffff;
      }
    }
  }
  .base {
    background: #1c2026;
    .info,
    .icp {
      display: flex;
      justify-content: center;
    }
    .info {
      height: 223px;
      > div {
        &:last-child {
          border-right: 1px solid #373a40;
        }
        border-left: 1px solid #373a40;
      }
      .text-box {
        padding: 40px 80px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #a4a5a8;
        line-height: 20px;
        > div + div {
          margin-top: 14px;
        }
        > div:first-child {
          font-family: PingFangSC-Medium;
          font-weight: 500;
          color: #ffffff;
        }
        a {
          color: #a4a5a8;
          text-decoration: unset;
          &:hover {
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
      .icon {
        padding: 63px 90px;
        img {
          width: 206px;
          height: auto;
        }
      }
    }
    .icp {
      line-height: 80px;
      border-top: 1px solid #373a40;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #a4a5a8;
      span + span {
        margin-left: 43px;
      }
      a {
        color: inherit;
        text-decoration: unset;
      }
    }
  }
}
</style>
