import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    title: ''
  },
  mutations: {
    SET_TITLE(state, payload) {
      state.title = payload;
    }
  },
  actions: {},
  getters: {
    title: state => state.title
  }
});
