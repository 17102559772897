<!--
 * @Date: 2023-03-16 15:45:04
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-06-12 10:16:07
 * @Description: 
-->
<template>
  <div id="header">
    <img src="./logo.png" alt="" style="position: fixed; z-index: -9999; top: 0; right: 0" />
    <div class="bg"></div>
    <div class="menu-box">
      <el-menu mode="horizontal">
        <el-menu-item index="home">
          <a href="/" :class="checkAct('')">首页</a>
        </el-menu-item>
        <el-submenu index="business" popper-class="header-popper">
          <template slot="title">
            <a href="javascript:;" :class="checkAct('business')">业务领域</a>
          </template>
          <subMenuView title="业务领域" :menu="business_subMenu"></subMenuView>
        </el-submenu>
        <el-submenu index="2" popper-class="header-popper">
          <template slot="title">
            <a href="javascript:;" :class="checkAct('plan')">产品解决方案</a>
          </template>
          <subMenuView title="产品解决方案" :menu="subMenu"></subMenuView>
        </el-submenu>
        <el-menu-item index="3">
          <a href="/department.html" :class="checkAct('department')">合作伙伴</a>
        </el-menu-item>
        <el-menu-item index="news">
          <a href="/news.html" :class="checkAct('news')">新闻中心</a>
        </el-menu-item>
        <el-submenu index="about" popper-class="header-popper">
          <template slot="title">
            <a href="javascript:;" :class="checkAct('about')">关于我们</a>
          </template>
          <subMenuView title="关于我们" :menu="about_subMenu"></subMenuView>
        </el-submenu>
        <el-menu-item index="5">
          <a href="/join.html" :class="checkAct('join')">加入我们</a>
        </el-menu-item>
      </el-menu>
    </div>
    <a href="/">
      <img src="@/assets/imgs/logo1.svg" alt="" class="logo" />
    </a>
  </div>
</template>

<script>
import subMenuView from './subMenu.vue';
export default {
  components: {
    subMenuView
  },
  data() {
    return {
      business_subMenu: [
        { title: '咨询服务', url: '/business.html#zxfw' },
        { title: '定制开发', url: '/business.html#dzkf' },
        { title: '产品服务', url: '/business.html#cpfw' },
        { title: '集成服务', url: '/business.html#jcfw' },
        { title: '硬件研发', url: '/business.html#yjkf' }
      ],
      about_subMenu: [
        { title: '企业介绍', url: '/about.html#gsjs' },
        { title: '使命愿景', url: '/about.html#smyj' },
        { title: '业务架构', url: '/about.html#ywjg' },
        { title: '荣誉专利', url: '/about.html#qyry' }
      ],
      subMenu: [
        { title: '数字建造', url: '/plan.html?type=SZJZ' },
        { title: '数字城管', url: '/plan.html?type=SZCG' },
        { title: '数字房管', url: '/plan.html?type=SZFG' },
        { title: '数字水务', url: '/plan.html?type=SZSW' },
        { title: '数字企管', url: '/plan.html?type=SZQY' },
        { title: '数字基运', url: '/plan.html?type=SZJY' },
        { title: '数字园区', url: '/plan.html?type=SZYQ' },
        { title: '数字园林', url: '/plan.html?type=SZYL' }
      ]
    };
  },
  created() {},
  computed: {},
  methods: {
    checkAct(key) {
      return (key && window.location.href.indexOf('/' + key) >= 0) ||
        (!key && window.location.href === window.location.origin + '/')
        ? 'active'
        : '';
    }
  }
};
</script>

<style lang="less" scoped>
#header {
  width: 100vw;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  box-shadow: 0 2px 10px 0 #0262e90d;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    backdrop-filter: blur(4px);
    z-index: -1;
  }
  .logo {
    width: 124px;
    height: auto;
    cursor: pointer;
  }
  .menu-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    pointer-events: none;
    * {
      pointer-events: auto;
    }
    .menu-box {
      width: 148px;
    }
    :deep(.el-menu.el-menu--horizontal) {
      .el-menu-item {
        border-bottom: unset !important;
      }
      border-bottom: unset;
      .el-menu-item,
      .el-submenu__title {
        height: 70px;
        line-height: 70px;
        width: 148px;
        text-align: center;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 16px;
        color: #001c44;
        text-align: center;
      }
      a {
        text-decoration: unset;
        color: inherit;
        &.active {
          font-weight: 600;
          color: #0262e9;
        }
      }
    }
  }
  :deep(.el-submenu__icon-arrow.el-icon-arrow-down) {
    margin-top: 2px;
    font-size: 16px;
  }
}
</style>
<style>
.header-popper {
  left: calc(50% - 620px) !important;
}
</style>
