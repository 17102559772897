<!--
 * @Date: 2023-03-22 13:07:22
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-04-10 15:59:59
 * @Description: 
-->
<template>
  <div class="back-top" v-show="y >= 500 || showModal">
    <template v-if="!showModal">
      <div class="ask-btn" @click="showModal = true">
        <img src="@/assets/imgs/home_icon_customer service.svg" alt="" />
        <div>
          <div>咨</div>
          <div>询</div>
        </div>
        <div class="bg bg1"></div>
        <div class="bg bg2"></div>
        <div class="bg bg3"></div>
      </div>
      <div class="back-btn" @click="toTop"></div>
    </template>
    <template v-else>
      <div class="ask-modal">
        <div class="ask-header">
          <div>
            <img src="@/assets/imgs/home_icon_customer service.svg" alt="" />
            在线客服
          </div>
          <img
            class="close"
            src="@/assets/imgs/home_dialog box_icon_close.png"
            alt=""
            @click="showModal = false"
          />
        </div>
        <div class="ask-inner" ref="askInnerRef">
          <div class="ask-row-box" v-for="(item, index) in message" :key="index">
            <div class="ask-row" :class="item.align">
              <div class="message" v-html="item.message"></div>
            </div>
          </div>
        </div>
        <div class="ask-footer">
          <el-input v-model="val" @keyup.enter.native="send"></el-input>
          <div class="send" @click="send">发送</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { useScroll } from '@vueuse/core';
import hook from './hook';
const defaultMsg = `请问您需要咨询什么<br>
1、数字建造<br>
2、数字城管<br>
3、数字房管<br>
4、数字水务<br>
5、数字园林<br>
6、数字企管<br>
7、数字基运<br>
8、数字园区<br>
请回复：1、2、1与2、1与3、1与2与3……<br>
如以上选项无您所咨询的内容，请回复“其他”`;

export default {
  components: {},
  data() {
    return {
      y: 0,
      showModal: false,
      val: '',
      message: [
        {
          align: 'left',
          message: defaultMsg
        }
      ]
    };
  },
  mounted() {
    const { y } = useScroll(document.getElementById('page'));
    this.y = y;
    hook.$on('open', () => {
      this.showModal = true;
    });
  },
  methods: {
    toTop() {
      document.getElementById('page').scrollTo(0, 0);
    },
    send() {
      if (!this.val) return;
      this.message.push({
        align: 'right',
        message: this.val
      });
      if (/^[0-8]$|^[0-8](与[0-8])+$|^其他$/.test(this.val)) {
        this.message.push({
          align: 'left',
          message:
            '好的，请点击以下链接填写具体咨询内容及您的联系方式，我们将在48小时内联系您 <div><a href="/sendmsg.html?code=' +
            (this.val === '其他' ? 9 : this.val) +
            '">汇科智创-留言板</a></div>'
        });
      } else {
        this.message.push({ align: 'left', message: defaultMsg });
      }
      this.val = undefined;
      this.$nextTick(() => {
        this.$refs.askInnerRef.scrollTop = this.$refs.askInnerRef.scrollHeight;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.back-top {
  position: fixed;
  right: 80px;
  bottom: 15000 / 1080vh;
  z-index: 999;
  .ask-btn {
    width: 64px;
    height: 124px;
    border-radius: 32px;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(2, 98, 233, 0.3);
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .bg1 {
      background: linear-gradient(180deg, #98b8ff, #006aff);
    }
    .bg2 {
      background: #006aff;
      filter: blur(2px);
      opacity: 0.06;
    }
    .bg3 {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.2), transparent);
    }
    font-family: PingFangSC-Medium;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    line-height: 24px;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    img {
      width: 24px;
      height: auto;
    }
  }
  .back-btn {
    width: 64px;
    height: 64px;
    background: #ffffff;
    box-shadow: 0 2px 10px 0 #0262e91a;
    border-radius: 50%;
    background-image: url('~@/assets/imgs/backTop.svg');
    background-size: 100% 100%;
    cursor: pointer;
  }
  .ask-modal {
    width: 600px;
    height: 52000 / 1080vh;
    min-height: 520px;
    background: #ebeff4;
    box-shadow: 0 10px 30px 0 rgba(2, 98, 233, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    .ask-header {
      width: 600px;
      height: 48px;
      background: #006aff;
      box-shadow: 0 10px 30px 0 rgba(2, 98, 233, 0.1);
      display: flex;
      font-family: PingFangSC-SNaNpxibold;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;
      display: flex;
      padding: 0 24px;
      align-items: center;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
      .close {
        cursor: pointer;
      }
    }
    .ask-inner {
      flex: 1;
      min-height: 0;
      padding: 20px;
      overflow: auto;
      .ask-row-box {
        overflow: hidden;
        & + .ask-row-box {
          margin-top: 20px;
        }
      }
      .ask-row {
        max-width: 70%;
        display: flex;

        &.left {
          justify-content: flex-start;
          float: left;
          &::before {
            content: '';
            width: 48px;
            height: 48px;
            background-image: url(~@/assets/imgs/home_customer service avatar.png);
            margin-right: 12px;
          }
          .message {
            background-color: #fff;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #4e5b6d;
            line-height: 28px;
          }
          :deep(a) {
            color: #006affff;
            font-size: 16px;
            font-family: PingFangSC;
            line-height: 28px;
            text-decoration: unset;
          }
        }
        &.right {
          justify-content: flex-end;
          float: right;
          .message {
            background: #006aff;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #ffffff;
            line-height: 28px;
          }
        }
        .message {
          flex: 1;
          min-width: 0;
          padding: 14px 20px;
          border-radius: 4px;
        }
      }
      /* 滚动条 */
      &::-webkit-scrollbar {
        width: 6px;
      }
      &:hover::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-button {
        opacity: 0;
        height: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #fdfeff;
        border-radius: 3px;
        transition: 10s;
      }
      &::-webkit-scrollbar-thumb:hover {
        /*background: linear-gradient(rgb(38, 65, 221),rgb(143, 27, 158));*/
      }
      &::-webkit-scrollbar-track {
        height: 1px;
        /*background-color: rgb(206, 136, 233);*/
      }
      &::-webkit-scrollbar-corner {
        background: #fdfeff;
      }
    }
    .ask-footer {
      width: 600px;
      height: 88px;
      background: #ffffff;
      box-shadow: 0 10px 30px 0 #0262e91a;
      border-radius: 0 0 4px 4px;
      padding: 20px;
      display: flex;
      align-items: center;
      :deep(.el-input) {
        flex: 1;
        min-width: 0;
        width: unset;
      }
      .send {
        width: 96px;
        height: 48px;
        line-height: 48px;
        background: #006aff;
        border-radius: 4px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
        margin-left: 20px;
      }
    }
  }
}
</style>
