import axios from 'axios';
import qs from 'qs';
import { message } from 'element-ui';

// const BaseUrl = 'http://172.16.19.160:8011/api/ow/web/';
export const BaseUrl = process.env.VUE_APP_API_BASE_URL;

// 报错执行的操作
const errAct = function (error) {
  console.log(error);
  const data = error.response.data;
  const msg = data.message || 'Error';
  message.info({
    message: '系统维护中！',
    offset: 80
  });
  return Promise.reject(error);
};

const doService = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest'
  }
});
doService.interceptors.request.use(
  config => {
    config.timeout = config.timeout || 1000 * 60 * 1;
    config.baseURL = BaseUrl;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
doService.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code != 200) {
      if (res.code == 401) {
        message.info({
          message: '系统维护中！',
          offset: 80
        });
      }
      const msg = res.message || 'Error';
      message.error({
        message: msg,
        offset: 80
      });
      return Promise.reject(res);
    } else {
      return res;
    }
  },
  error => {
    errAct(error);
  }
);

const service = function () {
  return doService(...arguments);
};

const doFileUpload = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data',
    withCredentials: true
  },
  responseType: 'blob'
});
doFileUpload.interceptors.request.use(
  config => {
    config.baseURL = BaseUrl;
    const size = config.size;
    config.method = 'post';
    const file = config.data.file;
    if (!file) {
      throw Error('文件参数为 { file: *** }');
    }
    const formDatas = new FormData();
    if (Array.isArray(file)) {
      var allSize = 0;
      file.forEach(el => {
        allSize += el.size;
        formDatas.append('file', el);
      });
    } else {
      formDatas.append('file', file);
    }
    if ((allSize || file.size) / 1024 / 1024 > size) {
      message.error({
        message: `文件大小不得超过${size}M！`,
        offset: 80
      });
      return;
    }
    config.data.file = formDatas;
    for (var i in config.data) {
      if (i != 'file') {
        formDatas.append(i, config.data[i]);
      }
    }
    // 这部分非常重要，否则formdata会被转格式
    config.transformRequest = [
      function () {
        return formDatas;
      }
    ];
    config.responseType = 'json';
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
doFileUpload.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.code != 200) {
      if (res.code == 401) {
        message.info({
          message: '系统维护中！',
          offset: 80
        });
      }
      const msg = res.message || 'Error';
      message.error({
        message: msg,
        offset: 80
      });
      return Promise.reject(msg);
    } else {
      return res;
    }
  },
  error => {
    errAct(error);
  }
);

const fileUpload = function () {
  return doFileUpload(...arguments);
};

export { service, fileUpload };
