/*
 * @Date: 2022-06-28 09:43:16
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-05-16 08:58:08
 * @Description:
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index.js';

Vue.use(VueRouter);

const routes = [
  {
    path: '/'
  }
  // {
  //   path: '/overView/group',
  //   name: 'OverViewGroup',
  //   component: () => import('@/views/overView/group.vue'),
  //   meta: {
  //     title: '集团概况'
  //   }
  // }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  to.meta && to.meta.title && store.commit('SET_TITLE', to.meta.title);
  next();
});

export default router;
