<!--
 * @Date: 2023-03-30 20:28:35
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-04-10 09:32:58
 * @Description: 
-->
<template>
  <div class="show-more">
    <!-- <Button class="no-more" type="text" disabled>暂无更多数据</Button> -->
    <template v-if="nomore"></template>
    <Button class="loading" v-else-if="loading" loading>正在加载中</Button>
    <Button class="has" type="text" v-else @click="click">查看更多内容</Button>
  </div>
</template>

<script>
import { Button } from 'element-ui';
export default {
  components: {
    Button
  },
  props: {
    loading: Boolean,
    nomore: Boolean
  },
  methods: {
    click() {
      this.$emit('load');
    }
  }
};
</script>

<style lang="less" scoped>
.show-more {
  clear: both;
  text-align: center;
  margin-top: 50px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  .loading {
    border: unset;
    padding: 0;
  }
}
</style>
