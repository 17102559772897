<!--
 * @Date: 2023-04-02 16:38:42
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-04-04 17:25:55
 * @Description: 
-->
<template>
  <div class="detail-content">
    <div class="detail-inner">
      <div class="detail-title">{{ title }}</div>
      <div class="detail-sub">
        <slot name="sub">{{ time }}</slot>
      </div>
      <div class="detail-line"></div>
      <div class="detail-box">
        <slot name="default"></slot>
      </div>
      <div class="detail-line" v-if="showQr"></div>
      <slot name="subfix"></slot>
      <div class="detail-qr" v-if="showQr">
        <div class="detail-qr-text-box">
          <div class="detail-qr-text">扫描右侧二维码关注我们，了解更多汇科新闻</div>
          <img class="box-title-icon" src="@/assets/imgs/business area_title_sign.png" alt="" />
        </div>
        <div class="qr-box">
          <img src="http://ow-web.hysz.co/qr.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: String,
    time: String,
    showQr: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {}
};
</script>

<style lang="less" scoped>
.detail-content {
  width: 100%;
  background: #fff;
  .detail-inner {
    width: 1240px;
    margin: auto;
    padding: 70px 0 80px;
  }
  .detail-line {
    height: 1px;
    background: #ebeff4;
  }
  .detail-title {
    font-family: PingFangSC-Medium;
    font-weight: 500;
    font-size: 40px;
    color: #04152f;
    line-height: 60px;
    margin-bottom: 12px;
  }

  .detail-box {
    padding: 75px 0;
  }
  .detail-qr {
    margin-top: 90px;
    height: 228px;
    background-color: rgba(247, 249, 251, 0.7);
    padding: 24px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .detail-qr-text-box {
      display: flex;
      align-items: center;
      width: 860px;
      border-bottom: 2px solid;
      border-image: linear-gradient(90deg, rgba(0, 106, 255, 0.2), rgba(0, 106, 255, 0)) 2 2;
    }
    .detail-qr-text {
      font-family: YouSheBiaoTiHei;
      font-size: 24px;
      color: #4e5b6d;
      line-height: 36px;
      margin-right: 4px;
    }
  }
  .qr-box {
    width: 180px;
    height: 180px;
    background: #ebeff4;
    padding: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<style lang="less">
.detail-sub {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #818a97;
  line-height: 22px;
  margin-bottom: 17px;
  > div {
    margin-bottom: 12px;
  }
}
.detail-box {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #4e5b6d;
  line-height: 28px;
  text-indent: 32px;
  .title {
    font-family: PingFangSC-SNaNpxibold;
    font-weight: 600;
    font-size: 24px;
    color: #04152f;
    text-align: center;
    line-height: 36px;
    margin-bottom: 28px;
  }
  .sub-title {
    font-family: PingFangSC-Medium;
    font-weight: 500;
    font-size: 16px;
    color: #04152f;
    line-height: 28px;
    margin: 28px 0;
  }
  .sub-title2 {
    font-family: PingFangSC-Medium;
    font-weight: 500;
    font-size: 16px;
    color: #4e5b6d;
    line-height: 28px;
    margin: 28px 0;
  }
  .mark {
    font-family: PingFangSC-Medium;
    font-weight: 500;
    font-size: 16px;
    color: #4e5b6d;
    line-height: 28px;
  }
  a {
    color: rgba(0, 106, 255, 1);
    text-decoration: unset;
  }
}
</style>
