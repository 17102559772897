<!--
 * @Date: 2023-03-22 17:10:38
 * @LastEditors: 王子豪 2579405338@qq.com
 * @LastEditTime: 2023-04-10 16:51:00
 * @Description: 
-->
<template>
  <div class="tabs" ref="tabs">
    <div class="tabs-inner" :class="{ 'tabs-fix': tabsTop !== undefined && tabsTop <= 70 }">
      <div
        v-for="(el, index) of tabs"
        class="tabs-cell"
        :class="{ active: activeTab === index }"
        @click="change(el, index)"
      >
        {{ el.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { useElementBounding } from '@vueuse/core';
export default {
  components: {},
  props: {
    tabs: Array,
    actIndex: Number
  },
  data() {
    return {
      tabsTop: undefined,
      activeTab: 0
    };
  },
  created() {
    this.activeTab = this.actIndex || 0;
  },
  mounted() {
    let { top } = useElementBounding(this.$refs.tabs);
    this.tabsTop = top;
  },
  computed: {},
  methods: {
    changeTab(i) {
      this.activeTab = i;
    },
    change(el, i) {
      this.activeTab = i;
      this.$emit('change', el, i);
    }
  }
};
</script>

<style lang="less" scoped>
.tabs {
  width: 100%;
  height: 70px;
  .tabs-inner {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    background: #fff;
    &.tabs-fix {
      position: fixed;
      top: 70px;
      left: 0;
      background: #fff;
      z-index: 99;
    }
  }
  .tabs-cell {
    height: 70px;
    line-height: 70px;
    position: relative;
    font-family: PingFangSC-Regular;
    font-size: 20px;
    color: #04152f;
    text-align: center;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: transparent;
      transition: background linear 0.5s;
    }
    &.active,
    &:hover {
      color: #006aff;
      font-family: PingFangSC-SNaNpxibold;
      font-weight: 600;
      &::after {
        background: #006aff;
      }
    }
  }
}
</style>
